<template>
  <div class="admin">
    <div style="display: flex; padding: 20px;">
      <img src="/img/kolumbus-logo.svg" class="site-logo" style="height: 25px;" alt="Kolumbus logo"/>
      <h2 style="margin: 0 0 0 4vw; color: #f2f2f2;">
        <em>{{ environment }}</em> Testing av skjermvisning
      </h2>
    </div>

    <div class="content">
      <button v-show="btnShown" style="cursor: pointer;" title="Add to desktop" @click="installer">
        Installer som app på datamaskinen
      </button>

      <div class="nav">
        <div>
          <div>
            <router-link to="/">Default visning (home)</router-link>
          </div>
          <div>
            <router-link to="/soundtest">Lyd test</router-link>
          </div>
        </div>
        <div class="build-info">
          Commit ID: <br/>
          <div style="margin-left: 5px;">
            Klient
            <a :href="`https://bitbucket.org/kolumbuscode/repo-sanntidng-kiosk/commits/${gitHash}`" target="_blank">
              {{ gitHash ? gitHash.substring(0, 7) : 'Missing' }}
            </a>
            <div v-if="serverBuildInfo">
              Server
              <a :href="`https://bitbucket.org/kolumbuscode/repo-sanntidng-kiosk/commits/${serverBuildInfo.commitHash}`" target="_blank">
                {{ serverBuildInfo.commitHash ? serverBuildInfo.commitHash.substring(0, 7) : 'Missing' }}
              </a>
            </div>
          </div>
          Bygget: <br/>
          <div style="margin-left: 5px;">
            Klient
            {{ buildTime.isValid() ? buildTime.format('DD.MM.YYYY HH:mm') : 'Ugyldig dato' }}
            <div v-if="serverBuildInfo && serverBuildInfo.buildTime"> Server {{ $moment(serverBuildInfo.buildTime).format('DD.MM.YYYY HH:mm') }}</div>
          </div>
        </div>
      </div>

      <div>
        <h2>SignalR</h2>
        <div>
          <button @click="getConnection" style="margin-bottom: 8px;">Vis SignalR ConnectionId</button>
          <div v-if="myConnection != null" style="margin-bottom: 16px;">
            <div v-if="myConnection.connectionId">
              Min connectionID: {{ myConnection.connectionId }} <br>
              Connection state: {{ myConnection.connectionState }}
            </div>
            <div v-else>Ingen connection i denne nettleser instans</div>
          </div>
        </div>
        <button @click="fetchConnectionRecords" style="margin-bottom: 8px;">Vis SignalR forbindelser</button>
        <div style="display: flex;">
          <table v-if="connectionRecords.length > 0" class="connections-table">
            <thead>
            <tr>
              <th>Index</th>
              <th>ConnectionId</th>
              <th>Mac</th>
              <th>IP</th>
              <th>Opprettet</th>
              <th>Siste pushTest svar</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(connectionRecord, index) in connectionRecords" :key="connectionRecord.connectionId">
              <td>{{ index }}</td>
              <td>{{ connectionRecord.connectionId }}</td>
              <td>{{ connectionRecord.mac }}</td>
              <td>{{ connectionRecord.ip }}</td>
              <td>{{ connectionRecord.created ? $moment(connectionRecord.created).format('DD.MM.YYYY HH:mm') : '-' }}</td>
              <td>{{ connectionRecord.lastAlive ? $moment(connectionRecord.lastAlive).format('DD.MM.YYYY HH:mm') : '-' }}</td>
            </tr>
            </tbody>
          </table>
          <div v-if="connectionsByMac" style="margin-left: 2em;">
            Tilfeller hvor en skjerm-PC har flere SignalR forbindelser
            <ul v-for="mac in Object.keys(connectionsByMac)" :key="mac" style="list-style-type: none; padding: 0;">
              <li v-if="connectionsByMac[mac].length > 1">{{ mac }}
                <ul v-for="ConnectionInfo in connectionsByMac[mac]" :key="ConnectionInfo.connectionId" style="list-style-type: disc;">
                  <li>{{ ConnectionInfo }}</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <h2 style="padding-top: 1em;">
        Kjøretøy
        <button style="margin-left: 2rem;" @click="getListOfActiveVehicles">
          Refresh nå <span style="color: #999;">{{ countdownToFetch }}</span>
        </button>
      </h2>
      <div>
        <label>
          <input v-model="marketingTestMode" type="checkbox" title="Skru av eller på test modus"/>
          Markedsinnhold i test modus
        </label>
        <small style="color: dimgray;">Visning i test modus gjør at avpublisert innhold også vises</small>
      </div>
      <div>
        <label>
          <input v-model="showNoTripVehicles" type="checkbox"/>
          Inkluder kjøretøy som ikke er på tur
        </label>
      </div>
      <admin-vehicle-dropdown v-for="vehicleState in vehicles"
                              :key="`route${vehicleState.vehicleId}`"
                              :title="`${(vehicleState.tripId ? `På tur ${vehicleState.tripId}` : 'Ikke på tur')}\nSiste GPS melding ${$moment(vehicleState.lastGpsMessageTimeUtc).format('DD.MM.YYYY HH:mm:ss')}`"
                              style="padding: 10px;"
                              :activator="vehicleState.vehicleId"
                              :isNotOnTrip="vehicleState.tripId == 0"
                              :items="vehicleState.screenDevices"
                              :marketing-test-mode="marketingTestMode">
      </admin-vehicle-dropdown>

      <h2 style="padding-top: 1em;">
        Båt
        <button style="margin-left: 2rem;" @click="getListOfActiveVehicles">
          Refresh nå <span style="color: #999;">{{ countdownToFetch }}</span>
        </button>
      </h2>
      <admin-vehicle-dropdown v-for="vehicleState in boats"
                              :key="`route${vehicleState.vehicleId}`"
                              :title="`${(vehicleState.tripId ? `På tur ${vehicleState.tripId}` : 'Ikke på tur')}\nSiste GPS melding ${$moment(vehicleState.lastGpsMessageTimeUtc).format('DD.MM.YYYY HH:mm:ss')}`"
                              style="padding: 10px;"
                              :activator="vehicleState.vehicleId"
                              :isNotOnTrip="vehicleState.tripId == 0"
                              :items="vehicleState.screenDevices"
                              :marketing-test-mode="marketingTestMode">
      </admin-vehicle-dropdown>
    </div>    
  </div>
</template>

<script>
import AdminVehicleDropdown from '../components/AdminVehicleDropdown'
import {logger} from '@/utils/logger'

export default {
  name: 'Admin',
  components: {AdminVehicleDropdown},
  data() {
    return {
      activeVehicles: [],
      btnShown: false,
      installPrompt: null,
      marketingTestMode: false,
      showNoTripVehicles: false,
      fetchIntervalThread: undefined,
      gitHash: process.env.VUE_APP_GIT_HASH && process.env.VUE_APP_GIT_HASH.indexOf('#') < 0 ? process.env.VUE_APP_GIT_HASH : 'Ukjent',
      buildTime: this.$moment(process.env.VUE_APP_BUILD_TIME),
      serverBuildInfo: null,
      environment: process.env.VUE_APP_ENV,
      idleVehiclesVisible: false,
      connectionRecords: [],
      myConnection: null,
      countdownToFetch: 30,
      countdownIntervalThread: null,
    }
  },
  created() {
    this.$appInsights.clearAuthenticatedUserContext()
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      this.installPrompt = e
      this.btnShown = true
    })
  },
  async mounted() {
    logger.debug('Env vars ' + JSON.stringify(process.env))
    this.getListOfActiveVehicles()
    this.countdownIntervalThread = setInterval(() => {
      this.countdownToFetch -= 1
      if (this.countdownToFetch === 0) this.countdownToFetch = 30
    }, 1000)
    this.fetchIntervalThread = setInterval(this.getListOfActiveVehicles, 30 * 1000)

    this.getServerBuildInfo()
  },
  beforeDestroy() {
    clearInterval(this.fetchIntervalThread)
    clearInterval(this.countdownIntervalThread)
  },
  computed: {
    connectionsByMac() {
      if (this.connectionRecords.length <= 0)
        return null

      return this.connectionRecords.reduce((acc, entry) => {
        if (!acc[entry.mac])
          acc[entry.mac] = []
        acc[entry.mac].push(entry)
        return acc
      }, {})
    },
    vehicles() {
      return this.showNoTripVehicles ? this.activeVehicles.filter(x => x.vehicleType < 2) : this.activeVehicles.filter(x => (x.vehicleType < 2) && (x.tripId > 0))
    },
    boats() {
      return this.activeVehicles.filter(x => x.vehicleType == 2)
    }
  },
  methods: {
    installer() {
      this.btnShown = false
      this.installPrompt.prompt()

      this.installPrompt.userChoice.then(result => {
        if (result.outcome === 'accepted') logger.debug('yay, accepted')
        else logger.debug('doh, denied')

        this.installPrompt = null
      })
    },
    async fetchConnectionRecords() {
      const response = await this.$http.get('api/signalr/connection-records')
      this.connectionRecords = response.data
    },
    getConnection() {
      // Watching this with computed function did not work, so doing this as a method instead
      this.myConnection = (this.$signalrConnection && this.$signalrConnection.connection) ?
          this.$signalrConnection.connection :
          {}
    },
    async getListOfActiveVehicles() {
      try {
        const response = await this.$http.get('api/screendevice/all')
        this.activeVehicles = response.data || []
      } catch (error) {
        logger.error('Failed to get list of active vehicles (ListVehicleStates)', error)
      }
    },
    async getServerBuildInfo() {
      try {
        const result = await this.$http.get('/api/version')
        this.serverBuildInfo = result.data
      } catch (error) {
        logger.error('Getting build info from server failed', error)
      }
    },
  },
}
</script>

<style scoped>
.admin {
  height: 100%;
  background-color: #333f48;
  overflow-y: scroll;
  color: black;
}

.content {
  background-color: #f2f2f2;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
}

.nav {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.nav div {
  padding-bottom: 10px;
}

.build-info {
  padding: 5px;
  background-color: #eaeaea;
  border-radius: 3px;
}

.connections-table {
  border-collapse: collapse;
}

.connections-table th,
td {
  border: 1px solid #ccc;
  padding: 3px;
}

.connections-table tbody tr:nth-child(2n) {
  background-color: white;
}

.connections-table tbody tr:nth-child(2n + 1) {
  background-color: rgba(100, 100, 100, 0.1);
}
</style>
